<template>
  <div class="full-screen-dialog">
    <v-dialog
      v-model="dialog"
      :fullscreen="mobile"
      :hide-overlay="mobile"
      max-width="400"
      scrollable
      transition="dialog-bottom-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="#3f51b5">
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{title}}</v-toolbar-title>
        </v-toolbar>

        <v-container fluid style="overflow-y: scroll; height: 100vh">
          <slot></slot>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    title: { type: String, default: "" }
  },
  data() {
    return {
      mobile: window.innerWidth <= 768
    };
  }
};
</script>

<style lang="sass" >
.v-dialog.v-dialog--active.v-dialog--persistent
  overflow-y: hidden
</style>